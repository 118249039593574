import Flagship from 'components/Flagship/Flagship';
import { ProductSliderProps } from 'components/Product/ProductsSlider/ProductsSlider';
import SponsoredProductsSlider from 'components/SponsoredProducts/SponsoredProductsSlider';
import { CriteoEventType } from 'constants/criteo';
import { CustomCampaignConfig, HomeConfig } from 'features/criteo/connector';
import useCriteoRetailMedia from 'hooks/criteo/useCriteoRetailMedia';
import { IBlockSponsoredProducts } from 'types/ContentfulTypes';
import { isMobile } from 'utils/screenUtils';
import ContentfulGroupingHeading from '../ContentfulGroupingHeading/ContentfulGroupingHeading';
import ContentfulGroupingWrapper from '../ContentfulGroupingWrapper/ContentfulGroupingWrapper';

const eventTypeMap: Record<IBlockSponsoredProducts['fields']['type'], CriteoEventType> = {
  Custom: 'viewCustomCampaign',
  Home: 'viewHome',
};

interface ContentfulBlockSponsoredProductsProps extends ProductSliderProps {
  className?: string;
  content: IBlockSponsoredProducts;
  hideTitle?: boolean;
}

const ContentfulBlockSponsoredProducts = ({
  alwaysShowArrows = false,
  className,
  content,
  hideTitle,
  slidesObject,
}: ContentfulBlockSponsoredProductsProps) => {
  const { campaignId, category, dataType = 'Sponsored Products', filters, showSignings, title, type } = content.fields;

  const eventType = eventTypeMap[type];

  const getEventConfig = () => {
    switch (eventType) {
      case 'viewHome': {
        const config: HomeConfig = {
          eventType,
          isMobile: isMobile(),
        };

        return config;
      }
      default: {
        // Fallback to custom campaign to ensure no empty config is passed
        const config: CustomCampaignConfig = {
          campaignId,
          category,
          eventType: 'viewCustomCampaign',
          filters,
          isMobile: isMobile(),
        };

        return config;
      }
    }
  };

  const { flagshipPlacements, sponsoredProductsPlacements } = useCriteoRetailMedia(getEventConfig());
  const hasFlagshipPlacements = !!flagshipPlacements?.length;
  const hasSponsoredProductsPlacements = !!sponsoredProductsPlacements?.length;

  const showTitle =
    !hideTitle &&
    ((dataType === 'Flagship' && hasFlagshipPlacements) ||
      (dataType === 'Sponsored Products' && hasSponsoredProductsPlacements));

  if (!content?.fields) {
    return null;
  }

  const sliderProps = {
    alwaysShowArrows,
    slidesObject,
  };

  return (
    <ContentfulGroupingWrapper className={className}>
      {showTitle && <ContentfulGroupingHeading>{title}</ContentfulGroupingHeading>}

      {dataType === 'Flagship' && hasFlagshipPlacements && <Flagship placement={flagshipPlacements[0]} />}

      {dataType === 'Sponsored Products' && hasSponsoredProductsPlacements && (
        <SponsoredProductsSlider
          placements={sponsoredProductsPlacements}
          showSignings={showSignings}
          {...sliderProps}
        />
      )}
    </ContentfulGroupingWrapper>
  );
};
export default ContentfulBlockSponsoredProducts;
